<script setup lang="ts">
import { ref, watch } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import ProfileDD from './ProfileDD.vue';

const messagedrawer = ref(false);

const customizer = useCustomizerStore();
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
watch(priority, (newPriority) => {
    priority.value = newPriority;
});
// const toggleSidebar = () => {
//   customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar);
// };
const user = useState<any>('user');

</script>

<template>
    <v-app-bar elevation="0" :priority="priority" height="74" class="px-sm-10 px-5">
        <v-btn class="hidden-md-and-down mr-5 ml-0" icon aria-label="sidebar button" rounded="sm"
            variant="text"  @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)" style="color:#40403F;width: 50px; height: 50px;">
            <v-icon size="36">mdi-menu</v-icon> 
        </v-btn>

        <v-btn class="hidden-lg-and-up mr-5 ml-0" icon rounded="sm" variant="text" size="large"
      @click.stop="customizer.SET_SIDEBAR_DRAWER()" 
      style="color:#40403F;width: 50px; height: 50px;">
      <v-icon size="36">mdi-menu</v-icon>
    </v-btn>



        <!-- search mobile -->
        <!-- <v-menu :close-on-content-click="false" class="hidden-lg-and-up" offset="10, 0">
            <template v-slot:activator="{ props }">
                <v-btn class="hidden-lg-and-up ml-1" color="secondary" icon rounded="sm" variant="text" size="small"
                    v-bind="props">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
<v-sheet class="search-sheet v-col-12 pa-0" elevation="24" width="320" rounded="md">
    <v-text-field persistent-placeholder placeholder="Search here.." rounded="md" color="primary" variant="solo"
        hide-details>
        <template v-slot:prepend-inner>
                        <v-icon>mdi-magnify</v-icon>
                    </template>
    </v-text-field>
</v-sheet>
</v-menu> -->

        <!-- <v-sheet color="transparent" class="d-none d-lg-block" width="224">
            <VerticalHeaderSearchBarPanel />
        </v-sheet> -->

        <v-spacer />

        <!-- Messages -->
        <!-- <v-menu :close-on-content-click="false" offset="10, 380">
            <template v-slot:activator="{ props }">
                <v-btn icon class="hidden-sm-and-down d-lg-block d-none" aria-label="Megamenu" color="secondary"
                    rounded="sm" variant="text" v-bind="props">
                    <v-icon>mdi-window-maximize</v-icon>
                </v-btn>
            </template> -->
        <!-- <v-sheet width="1024" height="325" rounded="md" class="d-lg-block d-none">
                <MegaMenuDD />
            </v-sheet> -->
        <!-- </v-menu> -->

        <!-- translate -->
        <!-- <v-menu :close-on-content-click="false" location="bottom" offset="6, 80">
            <template v-slot:activator="{ props }">
                <v-btn icon class="ml-sm-2 ml-1" color="secondary" aria-label="language button" rounded="sm"
                    v-bind="props">
                    <v-icon>mdi-translate</v-icon>
                </v-btn>
            </template>

        </v-menu> -->
        <!-- Message -->
        <!-- <v-btn icon class="ml-sm-2 ml-1" aria-label="message button" color="secondary" rounded="sm"
            @click.stop="messagedrawer = !messagedrawer">
            <v-icon>mdi-email-outline</v-icon>
        </v-btn> -->

        <!-- User Profile -->
        <v-menu :close-on-content-click="false" offset="8, 0">
            <template v-slot:activator="{ props }">
                <v-btn class="profileBtn mr-0 d-flex align-center justify-center" aria-label="profile" variant="tonal"
                    rounded="lg" 
                    style="padding: 20px;background-color: #40403F;color: white;"
                    v-bind="props">
                    <v-icon size="30" class="mr-2">mdi-account-circle</v-icon>
                    <span class="ml-2" style="font-weight: 500; font-size: 16px;">{{ user.name }}</span>
                </v-btn>
            </template>

            <v-sheet rounded="md" width="350">
                <ProfileDD />
            </v-sheet>
        </v-menu>



    </v-app-bar>

    <!-- <v-navigation-drawer app temporary elevation="24" location="right" border="0" v-model="messagedrawer" width="474"
        class="message-drawer" style="z-index: 1075 !important">
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <div class="py-4 px-5 d-flex justify-space-between align-center">
                    <div class="text-h5 font-weight-medium">What’s new announcement?</div>
                    <v-btn variant="text" color="secondary" icon aria-label="close" rounded="md"
                        @click="messagedrawer = !messagedrawer">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <perfect-scrollbar style="height: calc(100vh - 100px)">
            <v-list class="px-5" aria-label="message list" aria-busy="true">
                Message List
            </v-list>
        </perfect-scrollbar>
    </v-navigation-drawer> -->
</template>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@media (max-width: 600px) {
  .v-btn {
    display: block;
  }
}


</style>